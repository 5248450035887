export { default as adretail } from "./adretail.json"
export { default as casaideas } from "./casaideas.json"
export { default as entel } from "./entel.json"
export { default as logytech } from "./logytech.json"
export { default as nave } from "./nave.json"
export { default as omnix } from "./omnix.json"
export { default as privilege } from "./privilege.json"
export { default as sandbox } from "./sandbox.json"
export { default as shiperto } from "./shiperto.json"
export { default as tps } from "./tps.json"
export { default as wom } from "./wom.json"
