import CircularProgress from "@material-ui/core/CircularProgress"
import Link from "@material-ui/core/Link"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useEffect, useState } from "react"
import useStyles from "./styles"
import { useTheme } from "@material-ui/core/styles"
import { checkDownloadContent } from "@Services/downloadService"
import useCancelToken from "@Hooks/useCancelToken"
import axios from "axios"
import { useTranslation } from "react-i18next"
function Row({ info, deleteReport, setAvailable }) {
  const {
    reportId,
    name,
    initDate,
    finalDate,
    link,
    filename,
    creationDate,
    available,
  } = info
  console.log("info", info)
  const { t } = useTranslation("global")
  const theme = useTheme()
  const classes = useStyles()
  const [internalAvailable, setInternalAvailable] = useState(false)
  const [load, setLoad] = useState(false)
  const [needRetry, setNeedRetry] = useState(true)
  const { newCancelToken } = useCancelToken()

  useEffect(() => {
    if (available === false) {
      checkUrlAvailable(link)
    } else {
      setNeedRetry(false)
      setInternalAvailable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fuction to check and update the availabilty of the Field , use AxiosRetry (axios-retry library)
  const checkUrlAvailable = async (link) => {
    try {
      setLoad(true);
      setInternalAvailable(false);
      await checkDownloadContent(link, newCancelToken());
      setInternalAvailable(true);
      setAvailable(creationDate);
      setNeedRetry(false);
      setLoad(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(`Out row ${creationDate}`)
      } else {
        setInternalAvailable(false)
        setNeedRetry(true)
        setLoad(false)
      }
    }
  }

  const buildCreationDate = (timestamp) => {
    const date = new Date(timestamp)

    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    const time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    })

    return `${day}/${month}/${year} ${time}`
  }

  const name_report_parsed = filename.includes("orders_test") ? "orders_test_v2" : name

  return (
    <TableRow key={name + creationDate} size="small">
      <TableCell align="center" padding="checkbox">
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <span
            className={
              internalAvailable ? classes.dotAvailable : classes.dotEnable
            }
          />
        </div>
      </TableCell>
      <TableCell align="left">{t(name_report_parsed)}</TableCell>
      <TableCell align="left">{buildCreationDate(creationDate)}</TableCell>
      <TableCell align="left">{initDate}</TableCell>
      <TableCell align="left">{finalDate}</TableCell>
      <TableCell align="left">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            justifyContent: "left",
          }}
        >
          {load === true ? (
            <CircularProgress size={20} />
          ) : (
            <>
              {needRetry ? (
                <span
                  style={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => checkUrlAvailable(link)}
                >
                  {t("check")}
                </span>
              ) : (
                <Link
                  href={link}
                  rel="noopener"
                  color={internalAvailable ? "primary" : "inherit"}
                  target="_blank"
                >
                  {t("download")}
                </Link>
              )}
              |
              <span
                style={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={() => deleteReport(reportId, filename, creationDate)}
              >
                {t("delete")}
              </span>
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default Row
