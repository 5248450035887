import { authTypes } from "../types/authTypes"

export const loginStart = () => ({
  type: authTypes.login_start,
  payload: null,
})

export const loginError = () => ({
  type: authTypes.login_error,
  payload: null,
})

export const loginSuccess = (user) => ({
  type: authTypes.login_success,
  payload: user,
})

export const logout = () => {
  window.localStorage.removeItem("finalDate")
  window.location.reload()

  return {
    type: authTypes.logout,
    payload: null,
  }
}

export const change_user_info = (user_data) => ({
  type: authTypes.change_user_info,
  payload: user_data,
})
