const entel = {
  IN_RETURN: {
    HD: {
      order: "En retorno",
      sg: "En retorno",
    }
  },
  RETURNED: {
    HD: {
      sg: "Retornada",
      order: "Retornada"
    }
  }
}

export default entel;