import React from "react"
import { MultiClient, Env } from "@Classes"
import { getValueFromArray } from "@Methods/Utils"

/**
 * Load a React component by client and environment with Dynamic Imports{React.lazy and Suspense}
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @param {string} componentName
 * @param {string} groupName
 */
export default class LoadComponentByCondition {
  constructor(componentName, groupName = "orders", type = "components") {
    this.componentName = componentName
    this.groupName = groupName
    this.type = type
    this._href = window.location.href
  }

  /**
   * Check if the component is overwritten, if it is not overwritten, the default component is returned.
   * The theme is composed of three levels: core -> omnix -> [ClientName].
   * First we try to load the module that is overwritten in the [ClientName] theme, if it is not overwritten
   *    we will look for the theme called omnix and finally we try to render the core component.
   *
   * TODO: Move dinamic imports {core, omnix, child} to context
   *
   * @version 1.0.2
   * @author Naldo Duran <naldorck@gmail.com>
   *
   * @returns {Promise}
   */
  async fallbackTheme() {
    let [_client, _env] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

    const website = _client
    const env = _env

    const core = await import(`@Overrides/layout/core`)
    const resource = core["default"][this.type][this.componentName]

    const omnix = await import(`@Overrides/layout/omnix`)
    let themeOmnix = omnix["default"][env][this.type]
    themeOmnix = getValueFromArray(themeOmnix, this.componentName)

    const child = await import(`@Overrides/layout/${website}`)
    let resourceChild = child["default"][env][this.type]
    resourceChild = getValueFromArray(resourceChild, this.componentName)

    if (resourceChild.length) {
      return import(
        `@Overrides/theme/${website}/${env}/${this.type}/${resource}`
      )
    } else if (themeOmnix.length) {
      return import(`@Overrides/theme/omnix/${env}/${this.type}/${resource}`)
    } else {
      return import(`@Components/${resource}`)
    }
  }

  /**
   * Returns the component to be loaded dynamically
   *
   * @version 1.0.1
   * @author Naldo Duran <naldorck@gmail.com>
   *
   * @returns {Promise<React.Component>}
   */
  render() {
    return React.lazy(async () => {
      let resp = await this.fallbackTheme()
      return resp
    })
  }
}
