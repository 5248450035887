const casaideas = ({ reason }) => {
  const statusCanceledDecreaseWithReason = () => {
    return `Cancelado sin modificar inventario ${reason ? `- (${reason})` : ""}`
  }

  const statusCanceledWithReason = () => {
    return `Cancelada ${reason ? `- (${reason})` : ""}`
  }

  return {
    // Without Sgs
    RESERVED: {
      HD: {
        order: "Creada"
      },
      SP: {
        order: "Creada"
      },
      RT: {
        order: "Creada"
      }
    },
    PENDING_PREPARATION: {
      HD: {
        order: "Ingresada",
      },
      SP: {
        order: "Ingresada",
      },
      RT: {
        order: "Preparación pendiente",
      }
    },
    // With Sgs
    AWAITING_STORE_CONFIRMATION: {
      HD: {
        sg: "Ingresada",
      },
      SP: {
        sg: "Ingresada",
      },
      RT: {
        sg: "Ingresada",
      }
    },
    PENDING_PACKAGE: {
      HD: {
        sg: "Empacado"
      },
      SP: {
        sg: "Empacado"
      },
      RT: {
        sg: "Empacado"
      }
    },
    IN_SOURCE_PREPARATION: {
      SP: {
        order: "En preparación de productos",
        sg: "En preparación de productos"
      },
    },
    IN_TRANSIT: {
      HD: {
        order: "En ruta",
        sg: "En ruta"
      },
      RT: {
        order: "Devolución en ruta",
        sg: "Devolución en ruta"
      }
    },
    READY_FOR_PICKUP: {
      SP: {
        order: "Listo para retirar",
        sg: "Listo para retirar"
      },
    },
    CANCELED: {
      HD: {
        order: statusCanceledWithReason(),
        sg: statusCanceledWithReason()
      },
      SP: {
        order: statusCanceledWithReason(),
        sg: statusCanceledWithReason()
      },
      RT: {
        order: statusCanceledWithReason(),
        sg: statusCanceledWithReason()
      },
      TR: {
        order: statusCanceledWithReason(),
        sg: statusCanceledWithReason()
      }
    },
    CANCELED_DECREASE: {
      HD: {
        order: statusCanceledDecreaseWithReason(),
        sg: statusCanceledDecreaseWithReason()
      },
      SP: {
        order: statusCanceledDecreaseWithReason(),
        sg: statusCanceledDecreaseWithReason()
      },
      TR: {
        order: statusCanceledDecreaseWithReason(),
        sg: statusCanceledDecreaseWithReason()
      }
    },
  }
}

export default casaideas;