import { useState, useEffect, useContext } from "react"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import { createOrUpdateSource } from "@Services/sourceService"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

const useSourceCustomActionsOIM = ({sources, reloadTable}) => {
  const { t } = useTranslation("global")
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const [loadActionSource, setLoadActionSource] = useState(false)

  useEffect(() => {
    loadActionSource && Swal.fire({
      title: t("wait"),
      timerProgressBar: true,
      allowOutsideClick: false,
      showConfirmButton: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadActionSource])

  const catchErrorRequest = (error) => {
    if (error.response.status === 401) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    } else {
      Swal.fire({
        title: t("error"),
        text: error?.response?.data?.message,
        icon: "error",
        confirmButtonText: t("accept"),
      })
    }
  }

  const successResponse = () => {
    setLoadActionSource(false)
    Swal.fire(
      t("done"),
      t("sources_updated"),
      "success"
    )
    reloadTable(true)
  }

  const enableAllSelected = async () => {
    try {
      setLoadActionSource(true)
      await createOrUpdateSource(
        user?.token,
        sources.map(source => ({...source, enabled: true}))
      )
      successResponse()
    } catch (error) {
      catchErrorRequest(error)
    }
  }

  const disableAllSelected = async () => {
    try {
      setLoadActionSource(true)
      await createOrUpdateSource(
        user?.token,
        sources.map(source => ({...source, enabled: false}))
      )
      successResponse()
    } catch (error) {
      catchErrorRequest(error)
    }
  }

  const enableStorePickupInSelected = async () => {
    try {
      setLoadActionSource(true)
      await createOrUpdateSource(
        user?.token,
        sources.map(source => ({...source, storePickup: true}))
      )
      successResponse()
    } catch (error) {
      catchErrorRequest(error)
    }
  }

  const disableStorePickupInSelected = async () => {
    try {
      setLoadActionSource(true)
      await createOrUpdateSource(
        user?.token,
        sources.map(source => ({...source, storePickup: false}))
      )
      successResponse()
    } catch (error) {
      catchErrorRequest(error)
    }
  }

  return {
    enableAllSelected,
    disableAllSelected,
    enableStorePickupInSelected,
    disableStorePickupInSelected,
    actionsMenuWithLabels: [{
      label: `${t("enable")} ${t("sources")}`,
      onClick: enableAllSelected,
    }, {
      label: `${t("disable")} ${t("sources")}`,
      onClick: disableAllSelected,
    }, {
      label: `${t("enable")} ${t("store-pickup")}`,
      onClick: enableStorePickupInSelected,
    }, {
      label: `${t("disable")} ${t("store-pickup")}`,
      onClick: disableStorePickupInSelected,
    }]
  }
}

export default useSourceCustomActionsOIM
