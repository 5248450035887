import React, { useEffect, useContext, useState, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { GlobalContext } from "@Context/context/GlobalState"
import { getOrderDetails } from "@Services/orderService"
import { useEnv } from "@Hooks"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormOrders").render()

const EditOrderScreen = () => {
  const { orderId } = useParams()
  const user = useEnv().multiclient.get_user()
  const { handleRoutingBreadcrumbs, handleErrorSession } = useContext(GlobalContext)
  const [info, setInfo] = useState({})
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.ORDERS, rsc.ORDER_BUTTON_EDIT_ORDER],
        [rsc.OrCrudEditOrder, rsc.ORDER_BUTTON_EDIT_ORDER],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-order"),
      routes: [
        { name: t("orders"), route: "/orders" },
        { name: t("edit-order"), route: `/orders/edit/${orderId}` },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    loadDataOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadDataOrder = async () => {
    try {
      let response = await getOrderDetails(user?.token, orderId)
      setInfo(response.data.message)
      if (response?.data?.message?.currentStatus?.tag !== "RESERVED") {
        history.push("/orders")
      }
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component load={load} order={info} orderId={orderId} />
    </Suspense>
  )
}

export default EditOrderScreen
