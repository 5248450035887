import React, { useEffect, Suspense } from "react"
import rsc from "@Config/resources"
import { useEnv } from "@Hooks"
import { useHistory } from "react-router"
import { useGetAllScheduleSettingsDetailsOLM } from "@Hooks/requests"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormItems").render()

const CreateItem = () => {
  const history = useHistory()
  const { deliveryTypes, loadSchedule } = useGetAllScheduleSettingsDetailsOLM()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.ITEMS, rsc.ITEM_BUTTON_CREATE_ITEM],
        [rsc.ItCrudCreateItem, rsc.ITEM_BUTTON_CREATE_ITEM],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component load={loadSchedule} deliveryTypes={deliveryTypes} />
    </Suspense>
  )
}

export default CreateItem
