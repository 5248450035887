import HomeIcon from "@material-ui/icons/Home"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import GetAppIcon from "@material-ui/icons/GetApp"
import PublishIcon from "@material-ui/icons/Publish"
import rsc from "@Config/resources"

const core = [
  {
    name: "home",
    type: "simple",
    icon: HomeIcon,
    redirect: "/dashboard",
    permanent: true,
    envs: ["dev", "uat", "prod"],
  },
  // ---
  {
    name: "orders",
    type: "collapse",
    icon: CardTravelIcon,
    permissions: [rsc.OrdersGroup, rsc.ORDER_BUTTON_GROUP_SIDEBAR],
    envs: ["dev", "uat", "prod"],
    subItems: [{
      name: "detail-orders",
      redirect: "/orders",
      permissions: [rsc.OrdersGroup, rsc.ORDER_BUTTON_GROUP_SIDEBAR, rsc.ORDER_ITEM_ORDER_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "detail-shipping-groups",
      redirect: "/shipping_groups",
      permissions: [rsc.OrdersGroup, rsc.ORDER_BUTTON_GROUP_SIDEBAR, rsc.ORDER_ITEM_SG_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "create-order",
      redirect: "/orders/select-new",
      permissions: [rsc.OrdersGroup, rsc.ORDER_BUTTON_CREATE_ORDER, rsc.ORDER_ITEM_ORDER_CREATE_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }],
  },
  // ---
  {
    name: "logistics",
    type: "collapse",
    icon: LocalShippingIcon,
    permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
    envs: ["dev", "uat", "prod"],
    subItems: [{
      name: "schedule",
      redirect: "/schedule",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_SCHEDULE_SIDEBAR],
      envs: ["dev", "uat"]
    }, {
      name: "sources",
      redirect: "/sources",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_SOURCES_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "couriers",
      redirect: "/couriers",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_COURIERS_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "capacity-used",
      redirect: "/use-capacity",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_CAPACITY_USED_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "routes",
      redirect: "/routes",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_ROUTES_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "coverage",
      redirect: "/coverage",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_COVERAGE_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "locations",
      redirect: "/locations",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_LOCATIONS_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "calendar",
      redirect: "/calendar",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR, rsc.LOGISTIC_ITEM_CALENDAR_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }],
  },
  // ---
  {
    name: "inventory",
    type: "collapse",
    icon: AllInboxIcon,
    permissions: [rsc.InventoryGroup, rsc.INVENTORY_BUTTON_GROUP_SIDEBAR],
    envs: ["dev", "uat", "prod"],
    subItems: [{
      name: "sales-channels",
      redirect: "/channels",
      permissions: [rsc.InventoryGroup, rsc.INVENTORY_BUTTON_GROUP_SIDEBAR, rsc.INVENTORY_ITEM_SALES_CHANNEL_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "stock",
      redirect: "/stock",
      permissions: [rsc.InventoryGroup, rsc.INVENTORY_BUTTON_GROUP_SIDEBAR, rsc.INVENTORY_ITEM_STOCK_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "articles",
      redirect: "/items",
      permissions: [rsc.InventoryGroup, rsc.INVENTORY_BUTTON_GROUP_SIDEBAR, rsc.INVENTORY_ITEM_ARTICLES_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }],
  },
  // ---
  {
    name: "massive-loads",
    type: "simple",
    icon: PublishIcon,
    redirect: "/massive_loads",
    permissions: [rsc.LoadGroup, rsc.MASSIVE_LOAD_BUTTON_SIDEBAR],
    envs: ["dev", "uat"],
  },
  // ---
  {
    name: "reports",
    type: "simple",
    icon: GetAppIcon,
    redirect: "/dowloadReports",
    permissions: [rsc.ReportGroup, rsc.REPORT_BUTTON_SIDEBAR],
    envs: ["dev", "uat", "prod"],
  },
]

export default core