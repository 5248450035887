import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "@Context/context/GlobalState"
import { useEnv } from "@Hooks"
import SettingsIcon from "@material-ui/icons/Settings"
import { useTranslation } from "react-i18next"
import { getSequenceConfig } from "@Services/orderService"
import FormIncrementalOmnixId from "@Components/FormIncrementalOmnixId"
import rsc from "@Config/resources"

const NewIncrementalOmnixIdScreen = () => {
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const user = useEnv().multiclient.get_user()
  const history = useHistory()
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.SETTINGS, rsc.SETTING_BUTTON_SET_INCREMENTAL_OMNIX_ID],
        [rsc.Setting, rsc.SETTING_BUTTON_SET_INCREMENTAL_OMNIX_ID],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("incrementalOmnixId-configured-correctly"),
      routes: [
        { name: t("settings"), route: "/settings" },
        {
          name: t("incrementalOmnixId-configured-correctly"),
          route: "/settings/incremental_omnix_id/new",
        },
      ],
      icon: <SettingsIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getIncrementalOmnix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const getIncrementalOmnix = async () => {
    try {
      await getSequenceConfig(user?.token)
      history.push("/settings/incremental_omnix_id")
    } catch (error) {
      if (error.response.status === 404) {
        return setLoad(false)
      } else {
        return handleErrorSession({
          error: true,
          errorCode: error.response.status,
        })
      }
    }
  }

  return <FormIncrementalOmnixId load={load} />
}

export default NewIncrementalOmnixIdScreen
