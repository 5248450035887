import { MultiClient, Env } from "@Classes"
import defaultTranslate from "./es.js"
import coreTranslate from "./es_core.js"
import casaideasTranslate from "./es_casaideas.js"
import sodimacTranslate from "./es_sodimac.js"
import tpsTranslate from "./es_tps.js"
import logytechTranslate from "./es_logytech.js"
import entelTranslate from "./es_entel.js"
import permissions from "./es_permissions.json"


const getTranslate = (word, fileTranslated) => {
  if (fileTranslated.hasOwnProperty(word)) {
    return fileTranslated[word]
  } else {
    return defaultTranslate[word] || word
  }
}

const _translate = (word, permission = false) => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  if (permission) {
    return permissions[word] || word
  }

  switch (_client) {
    case "casaideas":
      return getTranslate(word, casaideasTranslate)
    case "shiperto":
    case "logytech":
      return getTranslate(word, logytechTranslate)
    case "sodimac":
      return getTranslate(word, sodimacTranslate)
    case "tps":
      return getTranslate(word, tpsTranslate)
    case "entel":
      return getTranslate(word, entelTranslate)
    default:
      return getTranslate(word, coreTranslate)
  }
}

export default _translate
