const tpsTranslate = {
  "CREATED": "Creada",
  "READY_TO_GO": "Lista para bajar",
  "IN_TRANSIT": "En ruta de bajada",
  "GATEIN_COMPLETE": "Gate In TPS",
  "CLOSED": "Gate Out TPS",
  "CANCELED": "Cancelada",
  "LABEL_FOOTER_contactedTrucks": "Camiones contactados",
  "LABEL_FOOTER_pendingContactedTrucks": "Camiones pendientes por llamar",

  "LABEL_FOOTER_CREATED": "Nº ZEAL - Disponibles",
  "LABEL_FOOTER_READY_TO_GO": "Nº ZEAL - Agendados para salir",
  "LABEL_FOOTER_IN_TRANSIT": "Nº En Transito - C. la pólvora",
  "LABEL_FOOTER_GATEIN_COMPLETE": "Nº en TPS",
  "LABEL_FOOTER_CLOSED": "Nº Estado Final",
}

export default tpsTranslate
