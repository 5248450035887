import rsc from "@Config/resources"

export const values = [
  {
    id: "orders",
    filterType: ["range"],
    name: "orders",
    resourceIds: [rsc.ReportOrder, rsc.REPORT_OPTION_ORDERS],
  },
  {
    id: "orders_test_v2",
    filterType: ["range"],
    name: "orders_test_v2",
    resourceIds: [rsc.ReportOrder, rsc.REPORT_OPTION_ORDERS_V2],
  },
  {
    id: "item_order",
    filterType: ["range"],
    name: "item_order",
    resourceIds: [rsc.ReportOrderDetail, rsc.REPORT_OPTION_ITEM_ORDER],
  },
  {
    id: "availability",
    filterType: ["channel"],
    name: "availability",
    resourceIds: [rsc.Disponibility, rsc.REPORT_OPTION_AVAILABILITY],
  },
  {
    id: "orders_in_reserve",
    filterType: ["range"],
    name: "orders_in_reserve",
    resourceIds: [rsc.OrderReserve, rsc.REPORT_OPTION_ORDERS_IN_RESERVE],
  },
  {
    id: "output_of_service_request",
    filterType: ["range"],
    name: "output_of_service_request",
    resourceIds: [rsc.RequestMs, rsc.REPORT_OPTION_OUTPUT_OF_SERVICE_REQUEST],
  },
  {
    id: "transactions",
    filterType: ["range"],
    name: "transactions",
    resourceIds: [rsc.Transactions, rsc.REPORT_OPTION_TRANSACTIONS],
  },
  {
    id: "order_operation",
    filterType: ["range"],
    name: "order_operation",
    resourceIds: [rsc.ReportOperation, rsc.REPORT_OPTION_ORDER_OPERATION],
  },
  {
    id: "items",
    filterType: [],
    name: "items",
    resourceIds: [rsc.ReportItems, rsc.REPORT_OPTION_ITEMS],
  },
  {
    id: "capacity",
    filterType: ["range"],
    name: "capacity",
    resourceIds: [rsc.ReportCapacity, rsc.REPORT_OPTION_CAPACITY],
  }
]
