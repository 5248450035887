import { Permissions } from "@Classes"
import LabelSwitch from "@Components/core/Permissions/LabelSwitch"
import useStyles from "@Components/core/Permissions/styles"

const Permission = (props) => {
  const classes = useStyles()
  const { id, level, parent, checked, value, system_permission_list, set_user_permission_list } = props
  const props_to_permissions = { id, level, parent, checked, value }
  const permissions = new Permissions()

  const template = (
    <div
      className={classes[`new_user_screen__content_checked_${level}`]}
    >
      <LabelSwitch
        id={`perm--${JSON.stringify(props_to_permissions)}`}
        label={id}
        value={JSON.stringify(props_to_permissions)}
        onChange={(e) => permissions.change_list(e, set_user_permission_list, system_permission_list)}
        checked={checked}
      />
    </div>
  )

  return template
}

export default Permission
