import React, { useState, useEffect } from "react"
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TreeViewCustomFields from "@Components/CustomTreeView"
import useStyles from "./styles"
import { useTranslation } from "react-i18next"
import { useEnv } from "@Hooks"
import rsc from "@Config/resources"

const ProfileBody = () => {
  const { t } = useTranslation("global")
  const classes = useStyles()
  const use_env = useEnv()
  const user = use_env.multiclient.get_user()
  const [listPermissions, setListPermissions] = useState({})
  const [show_permission_list, set_show_permission_list] = useState(false)

  const { multiclient } = useEnv()

  useEffect(() => {
    const _allowed = multiclient.permissions_v3_on(
      [rsc.SETTINGS, rsc.SETTINGS_USER_SHOW_SECURITY_SCHEMAS_LIST],
      [rsc.SETTINGS_USER_SHOW_SECURITY_SCHEMAS_LIST],
    )

    set_show_permission_list(_allowed)
    setListPermissions(multiclient.get_permission_list())
    // setListPermissions(multiclient.get_permission_list() || permission) -> both solutions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const PermissionList = () => (
    show_permission_list && <TableRow>
      <TableCell>
        <Typography variant="body2" className={classes.labels}>
          {t("resources")}
        </Typography>
        <TreeViewCustomFields json={{ listPermissions }} fieldNeedTranslation />
      </TableCell>
    </TableRow>
  )

  return (
    <div>
      <TableContainer component={Paper} className={classes.mainContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("id")}
              </Typography>
              <Typography variant="body2">{user?.id}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("name")}
              </Typography>
              <Typography variant="body2">{user?.name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("email")}
              </Typography>
              <Typography variant="body2">{user?.email}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("role")}
              </Typography>
              <Typography variant="body2">{user?.role}</Typography>
            </TableCell>
          </TableRow>
          {<PermissionList />}
        </Table>
      </TableContainer>
    </div>
  )
}

export default ProfileBody
