import React, { useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import HomeIcon from "@material-ui/icons/Home"
import { useTranslation } from "react-i18next"
import { Multiclient } from "@Components"

const HomeScreen = () => {
  const { t } = useTranslation("global")
  const {
    handleRoutingBreadcrumbs,
    // handleErrorSession
  } = useContext(GlobalContext)

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("home"),
      routes: [{ name: t("home"), route: "/dashboard" }],
      icon: <HomeIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Multiclient path_override="components/Home/HomeContent" />
}

export default HomeScreen
