import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import useStyles from "@Components/CommonReport/MainPageStyle/styles"
import CommonReport from "@Components/CommonReport/CreateReport"
import MassiveLoadsTable from "@Components/TableMassiveLoads/MassiveLoadsTable"
import PopOverFilters from "@Components/TableMassiveLoads/PopOverFilters"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { values } from "@Methods/LoadFilterByResourceId/massiveLoads"
import getOptionsFilterSources from "@Methods/LoadFilterByResourceId/LoadFilterByResourceId"
import { useEnv } from "@Hooks"

const TableMassiveLoads = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation("global")
  const [canDoMassiveLoads, setCanDoMassiveLoads] = useState(false)
  const [permission, set_permission] = useState({})
  const { multiclient } = useEnv()

  useEffect(() => {
    const _allowed = multiclient.get_permission_list_state(rsc.MASSIVE_LOAD, [
      rsc.CmCrudLoadRoutecoverage,
      rsc.MASSIVE_LOAD_BUTTON_CREATE_MASSIVE_LOAD,
      rsc.CmFilterCmId,
      rsc.MASSIVE_LOAD_FILTER_MASSIVE_LOAD_ID,
    ])

    set_permission(_allowed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanDoMassiveLoads(permission[rsc.CmCrudLoadRoutecoverage] || permission[rsc.MASSIVE_LOAD_BUTTON_CREATE_MASSIVE_LOAD])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission])

  const ButtonRedirect = () => {
    if (!canDoMassiveLoads) return null
    return (
      <div className={classes.buttonGroupRight}>
        <Button
          onClick={() => history.push("/massive_loads/new")}
          color="primary"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
        >
          {t("new-bulk-upload")}
        </Button>
      </div>
    )
  }
  return (
    <>
      <Paper className={classes.styledPaper}>
        <CommonReport
          TableComponent={MassiveLoadsTable}
          PopOverComponent={PopOverFilters}
          ButtonRedirect={ButtonRedirect}
          tableName={"massive_loads"}
          defaultValueFilters={values}
          optionsFilter={getOptionsFilterSources(permission, values)}
        />
      </Paper>
    </>
  )
}

export default TableMassiveLoads
