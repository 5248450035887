/**
 * Role/permissions logic. Get configuration and resources from environment
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @returns {void}
 *
 */
import { useEffect, useState } from "react"

const usePermissions = () => {
     // eslint-disable-next-line
    const [data, set_data] = useState([])


    useEffect(() => {

    }, [])

}

export default usePermissions