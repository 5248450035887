import React, { useState, useEffect, Suspense, useContext } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getSources, getSourcesOLM } from "@Services/sourceService"
import { GlobalContext } from "@Context/context/GlobalState"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useEnv } from "@Hooks"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormSources").render()

const EditSourceScreen = () => {
  const { sourceId } = useParams()
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession, handleRoutingBreadcrumbs } =
    useContext(GlobalContext)
  const [source, setSource] = useState({})
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.SOURCES, rsc.SOURCE_BUTTON_EDIT_SOURCE],
        [rsc.SoCrudEditSource, rsc.SOURCE_BUTTON_EDIT_SOURCE]
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-source"),
      routes: [
        { name: t("logistics"), route: "/sources" },
        { name: t("edit-source"), route: `/sources/edit/${sourceId}` },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getSourceById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSourceById = async () => {
    let params = {
      skipRecords: 0,
      maxRecords: 1,
      filter: { id: sourceId },
    }
    try {
      let response = await getSources(user?.token, params)
      let responseOLM = await getSourcesOLM(user?.token, params)
      if (
        response.data.message.numRecordsReturned > 0 &&
        responseOLM.data.message.numRecordsReturned > 0
      ) {
        let {
          dispatchCapacity,
          volumeCapacity,
          crossdock,
          schedule,
          workingSessions,
          workingTime,
          calendarSettings,
        } = responseOLM.data.message.records[0]
        setSource({
          ...response.data.message.records[0],
          dispatchCapacity: dispatchCapacity || [],
          volumeCapacity: volumeCapacity || [],
          crossdock: crossdock || [],
          schedule: schedule || [],
          workingSessions: workingSessions || [],
          workingTime: workingTime || [],
          calendarSettings: calendarSettings || {},
        })
        setLoad(false)
      } else if (
        response.data.message.numRecordsReturned > 0 &&
        responseOLM.data.message.numRecordsReturned === 0
      ) {
        setSource({
          ...response.data.message.records[0],
          dispatchCapacity: [],
          volumeCapacity: [],
          crossdock: [],
          schedule: [],
          workingSessions: [],
          workingTime: [],
          calendarSettings: {},
        })
        setLoad(false)
      } else {
        // TODO Make a error object. Remove next line
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 404 } }
      }
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component source={source} edit={true} load={load} />
    </Suspense>
  )
}

export default EditSourceScreen
