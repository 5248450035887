import {useState, useEffect, useContext} from "react";
import { getChannels } from "@Services/channelService"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"

const useGetChannelsOIM = (params = {}) => {
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const [channels, setChannels] = useState([])
  const [channelsIds, setChannelsIds] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    getChannelsAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getChannelsAction = async () => {
    try {
      let response = await getChannels(user?.token, params)
      let result = response?.data?.message || []
      setChannels(result)
      setChannelsIds(result.map(({id}) => id))
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return { channels, channelsIds, load }
}

export default useGetChannelsOIM