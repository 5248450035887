const entelTranslate = {
  "051": "PERÚ",
  "150000": "LIMA",
  "150100": "LIMA",
  "70000": "CALLAO",
  "070100": "CALLAO",
  "070101": "CALLAO",
  "40000": "AREQUIPA",
  "040100": "AREQUIPA",
  "040101": "AREQUIPA",
  "150122": "MIRAFLORES",
  "150131": "SAN ISIDRO",
  "150136": "SAN MIGUEL",
  "HD": "Entrega a domicilio",
  "SP": "Retiro en tienda",
  "RT": "Retorno",
  "TR": "Transferencia"
}

export default entelTranslate
