import { BrowserRouter as Router, Switch } from "react-router-dom"
import PrivateRoute from "@Components/PrivateRoute"
import PublicRoute from "@Components/PublicRoute"
import DashboardContainer from "../containers/dashboard"
import { useEnv } from "@Hooks"
import LoginScreen from "@Pages/login"

const AppRouter = () => {
  const user = useEnv().multiclient.get_user()

  return (
    <Router>
      <Switch>
        <PublicRoute
          exact
          path="/login"
          component={LoginScreen}
          isAuthenticated={!!user}
        />
        <PrivateRoute
          path="/"
          component={DashboardContainer}
          isAuthenticated={!!user}
        />
      </Switch>
    </Router>
  )
}

export default AppRouter
