import React, { useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import PublishIcon from "@material-ui/icons/Publish"
import FormMassiveLoads from "@Components/FormMassiveLoads"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useEnv } from "@Hooks"

import { useTranslation } from "react-i18next"
const NewMassiveLoadsScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.MASSIVE_LOAD, rsc.MASSIVE_LOAD_BUTTON_CREATE_MASSIVE_LOAD],
        [rsc.CmCrudLoadRoutecoverage, rsc.MASSIVE_LOAD_BUTTON_CREATE_MASSIVE_LOAD],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("new-bulk-upload"),
      routes: [
        { name: t("massive-loads"), route: "/massive_loads" },
        { name: t("new-bulk-upload"), route: "/massive_loads/new" },
      ],
      icon: <PublishIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FormMassiveLoads load={false} />
}

export default NewMassiveLoadsScreen
