import React, { useEffect, useState, useContext, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { getScheduleSettingsDetails } from "@Services/scheduleService"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormSchedule").render()

const EditScheduleScreen = () => {
  const user = useEnv().multiclient.get_user()
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const { scheduleId } = useParams()
  const [schedule, setSchedule] = useState({})
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.SCHEDULE, rsc.SCHEDULE_BUTTON_EDIT_SCHEDULE],
        [rsc.SchCrudEdit, rsc.SCHEDULE_BUTTON_EDIT_SCHEDULE],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-schedule"),
      routes: [
        { name: t("logistics"), route: "/schedule" },
        { name: t("edit-schedule"), route: `/schedule/edit/${scheduleId}` },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getScheduleAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getScheduleAction = async () => {
    try {
      let response = await getScheduleSettingsDetails(
        user?.token,
        scheduleId
      )
      setSchedule(response.data.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component schedule={schedule} edit={true} load={load} />
    </Suspense>
  )
}

export default EditScheduleScreen
