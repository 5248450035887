import { getAllScheduleSettingsDetails } from '@Services/scheduleService';
import {useState, useEffect, useContext} from "react";
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"

const useGetAllScheduleSettingsDetailsOLM = (body = {
  skipRecords: 0,
  maxRecords: 0
}) => {
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const [schedules, setSchedules] = useState([])
  const [deliveryTypes, setDeliveryTypes] = useState([])
  const [deliveryTypesWithTR, setDeliveryTypesWithTR] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    getSchedulesAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (schedules.length) {
      const dTypes = schedules.flatMap(sch => sch.deliveryType)
      const dTypesWithTR = schedules.map(sch => (sch.shippingType || []).includes("TR") && sch.deliveryType)
      setDeliveryTypes([...new Set(dTypes.filter(Boolean))])
      setDeliveryTypesWithTR([...new Set((dTypesWithTR || []).filter(Boolean))])
    }
  }, [schedules])

  const getSchedulesAction = async () => {
    try {
      let response = await getAllScheduleSettingsDetails(
        user?.token,
        body
      )
      let result = response?.data?.message?.records || []
      setSchedules(result)
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return {
    schedules,
    deliveryTypes,
    deliveryTypesWithTR,
    loadSchedule: load
  }
}

export default useGetAllScheduleSettingsDetailsOLM