import React, { useState, useEffect, useContext, Suspense } from "react"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import { getItems, getItemsQuantity } from "@Services/itemService"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useGetAllScheduleSettingsDetailsOLM } from "@Hooks/requests"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormItems").render()

const EditItem = () => {
  const { id, channel } = useParams()
  const [info, setInfo] = useState([])
  const [load, setLoad] = useState(true)
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const history = useHistory()
  const { deliveryTypes, loadSchedule } = useGetAllScheduleSettingsDetailsOLM()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.ITEMS, rsc.ITEM_BUTTON_EDIT_ITEM],
        [rsc.ItCrudEditItem, rsc.ITEM_BUTTON_EDIT_ITEM],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadItems = async () => {
    setLoad(true)
    try {
      let jsonValue = {
        channel: channel, //Cambiaron la propiedad antes era channel
        skipRecords: 0,
        maxRecords: 10,
        filter: { sku: id },
      }
      let responseItem = await getItems(user?.token, jsonValue)
      const responseItemQuantity = await handleQueryItemsQuantity([id], channel)

      setInfo({
        ...responseItem.data.message.records[0],
        quantity: responseItemQuantity[0].quantity,
        safety: responseItemQuantity[0].safety,
      })

      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const handleQueryItemsQuantity = async (filter, channel) => {
    try {
      //Group by Source
      const responseItemsQuatity = await getItemsQuantity(
        user?.token,
        filter,
        channel
      )
      return responseItemsQuatity.data.message
    } catch (error) {
      return [{ quantity: "Sin asignar", safety: {} }]
    }
  }

  return (
    <Suspense fallback>
      <Component
        item={info}
        load={load || loadSchedule}
        deliveryTypes={deliveryTypes}
      />
    </Suspense>
  )
}

export default EditItem
