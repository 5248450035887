import axios from "axios"
import { MultiClient, Env } from "@Classes"

const buildUrl = (group) => {
  const _process = process.env
  const [_client, _env] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)
  const env = _env?.toUpperCase()
  const currentWebsite = _client?.toUpperCase()
  if (group !== "DOWNLOAD") {
    const urlBase = _process[`REACT_APP_BASE_${env}_URL_${currentWebsite}`]
    const urlGroup = _process[`REACT_APP_API_URL_${group}`]
    return `${urlBase}-${urlGroup}`
  } else {
    const url = `${_process[`REACT_APP_BASE_${env}_URL_DOWNLOAD`]}/clients/${currentWebsite.toLowerCase()}`
    return url
  }
}

const axiosClient = axios.create({
  baseURL: buildUrl("OSM"),
})

export const axiosClientOOM = axios.create({
  baseURL: buildUrl("OOM"),
})

export const axiosClientOIM = axios.create({
  baseURL: buildUrl("OIM"),
})

export const axiosClientOLM = axios.create({
  baseURL: buildUrl("OLM"),
})

export const axiosClientDOWNLOAD = axios.create({
  baseURL: buildUrl("DOWNLOAD"),
})

export const axiosClientOSRM = axios.create({
  baseURL: buildUrl("OSRM"),
})

export const axiosClientPRIORITIZATION = axios.create({
  baseURL: buildUrl("PRIORITIZATION"),
})

export default axiosClient
