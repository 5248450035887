const tps = {
  // Without Sgs
  CREATED: {
    TPS: {
      order: "Creada",
    },
  },
  READY_TO_GO: {
    TPS: {
      order: "Listo para bajar",
    },
  },
  GATEIN_COMPLETE: {
    TPS: {
      order: "Gate In TPS",
    },
  },
  IN_TRANSIT: {
    TPS: {
      order: "En ruta de bajada",
    },
  },
  CANCELED: {
    TPS: {
      order: "Cancelada",
    },
  },
  CLOSED: {
    TPS: {
      order: "Gate Out TPS",
    },
  },
}

export default tps
