import {useState, useContext} from 'react'
import { getSgById } from '@Services/shippingGroupService'
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import Swal from 'sweetalert2'

const useCheckSgDetailsOOM = () => {
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const [currentSg, setCurrentSg] = useState(null)
  const [load, setLoad] = useState(false)

  const getSgInfo = async (sgId) => {
    try {
      setLoad(true)
      const response = await getSgById(user?.token, sgId)
      const sg = response?.data?.message || {}
      setCurrentSg(sg)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      if (error?.response?.status !== 404) {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      } else {
        Swal.fire({
          title: 'Error',
          text: 'The SG does not exist',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    }
  }

  return {
    currentSg,
    loadSgInfo: load,
    getSgInfo,
  }
}

export default useCheckSgDetailsOOM