import { useContext } from "react"
import { AuthContext } from "@Context/v3/context"
import { MultiClient, Env } from "@Classes"

/**
 * Multicliente logic. Get configuration and resources from environment
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 *
 */
const useEnv = () => {
  const { state } = useContext(AuthContext)
  const [client, env] = MultiClient.get_user_env(state.user?.mainGroup.id, Env.clients, Env.env, Env.defaults)
  //eslint-disable-next-line
  const theme = Env.theme
  // Next will be in a forEach, testing a single value...
  //eslint-disable-next-line
  const permission_list = state.user?.rolePermissions
  const str_default = "default"

  const core = {
    get_value_simple: (path) => {
      const replaced = path.replaceAll("/", "?.")
      //eslint-disable-next-line
      const value_client = eval(`theme?.${client}?.${replaced}`)
      //eslint-disable-next-line
      const value_core_filter = eval(`theme?.core?.${replaced}`)

      return value_client ?? value_core_filter
    },
    get_value: (path) => {
      let default_path = path.split("/")
      default_path[1] = str_default
      default_path = default_path.join("/")
      default_path = default_path.replaceAll("/", "?.")

      const replaced = path.replaceAll("/", "?.")
      //eslint-disable-next-line
      const value_client = eval(`theme?.${client}?.${replaced}`)
      //eslint-disable-next-line
      const value_client_default = eval(`theme?.${client}?.${default_path}`)
      //eslint-disable-next-line
      const value_core_filter = eval(`theme?.core?.${replaced}`)
      //eslint-disable-next-line
      const value_core_filter_default = eval(`theme?.core?.${default_path}`)

      return value_client ?? value_client_default ?? value_core_filter ?? value_core_filter_default
    },
    get_status_list_complex: (list, shippingtype_list) => {
      let output = []

      shippingtype_list.forEach((shipping_type) => {
        list[shipping_type]["status"].forEach((status) => {
          if (!output.includes(status)) {
            output = [...output, status]
          }
        })
      })

      return output
    },
  }

  const permissions = {
    get_permission: (permission_parent, permission_id) => {
      if (!permission_list) {
        return true
      }
      if (!!permission_id) {
        if (!!permission_list.hasOwnProperty(permission_parent)) {
          return !!permission_list[permission_parent].includes(permission_id)
        }
      }
      if (!!permission_list.hasOwnProperty(permission_parent)) {
        return !!permission_list[permission_parent]
      }

      return false
    },
  }

  const multiclient = {
    get_user: () => state.user,
    get_user_env: () => [client, env],
    permissions_v3_is_enable: () => Env.clients_permissions_v3.includes(client),
    get_permission_list: () => {
      return permission_list
    },
    get_permission_list_state: (permission_parent, list) => {
      let res = list.reduce((acc, current) => ({ ...acc, [current]: false }), {})

      if (Env.clients_permissions_v3.includes(client)) {
        list.forEach((_item) => (res[_item] = permissions.get_permission(permission_parent, _item)))
      } else {
        const permission_list_mix = state.user?.permission.reduce(
          (acc, next) => [...acc, ...next.rule?.conditions?.all[0]?.value],
          [],
        )

        for (let k in res) {
          res[k] = permission_list_mix.includes(k)
        }
      }

      return res
    },
    permissions_v3_on: (list, list_old) => {
      if (Env.clients_permissions_v3.includes(client)) {
        return permissions.get_permission(...list)
      } else {
        if (!!list_old && list_old.length) {
          const permission_list_old_mix = state.user?.permission.reduce(
            (acc, next) => [...acc, ...next.rule?.conditions?.all[0]?.value],
            [],
          )

          for (let _p of list_old) {
            if (permission_list_old_mix.includes(_p)) {
              return true
            }
          }

          return false
        } else {
          return true
        }
      }
    },
  }

  const sg = {
    get_sg_shippingtype_list_all: () => core.get_value(`sg`),
    get_shippingtype_list: () => core.get_value_simple(`sg/shipping_types`),
    get_sg_status_list: () => core.get_value(`sg/${str_default}/status`),
    get_sg_actions_list: () => core.get_value(`sg/${str_default}/actions`),
    get_sg_status_actions_list: () => core.get_value(`sg/${str_default}/status_actions`),
    get_sg_shippingtype_status_list: (shippingtype) => core.get_value(`sg/${shippingtype}/status`),
  }

  return {
    core,
    multiclient,
    sg,
    permissions,
  }
}

export default useEnv
