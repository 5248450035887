import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useEnv } from "@Hooks"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormStock").render()

const NewStockScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.STOCK, rsc.STOCK_BUTTON_CREATE_STOCK],
        [rsc.StoCrudCreate, rsc.STOCK_BUTTON_CREATE_STOCK]
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("create-stock"),
      routes: [
        { name: t("inventory"), route: "/stock" },
        { name: t("create-stock"), route: "/stock/new" },
      ],
      icon: <AllInboxIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component load={false} />
    </Suspense>
  )
}

export default NewStockScreen
