import axiosClient from "@Config/axios"
import tokenAuth from "../config/token";

export async function getListUsers(token, body) {
  tokenAuth(token, "OSM");
  const url = `user/all`
  let result = await axiosClient.post(url, body, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
  })
  return result
}

export async function getListRoles(token, params = {}) {
  tokenAuth(token, "OSM");
  const url = `role`
  let result = await axiosClient.get(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    params
  })
  return result
}

export const getUserByIdService = async (token, userId) => {
  tokenAuth(token, "OSM");
  const url = `user/${userId}`
  const response = await axiosClient.get(url, {
    headers: { "Content-Type": "application/json" }
  },
  )
  return response
}

export const enableUserService = async (token, userId, body = {}) => {
  tokenAuth(token, "OSM");
  const url = `user/${userId}/enable`
  const response = await axiosClient.post(url, body, {
    headers: { "Content-Type": "application/json" }
  },
  )
  return response
}

export const deleteUserService = async (token, userId) => {
  tokenAuth(token, "OSM");
  const url = `user/${userId}`
  let result = await axiosClient.delete(url, {
    headers: { Accept: "application/json", "Content-Type": "application/json" }
  })
  return result
}

export const createUserService = async (token, body) => {
  tokenAuth(token, "OSM");
  const url = "user"
  const response = await axiosClient.put(url, body, {
    headers: { "Content-Type": "application/json" },
  },
  )

  return response
}

export const updateUserService = async (token, userId, body) => {
  tokenAuth(token, "OSM");
  const url = `user/${userId}`
  const response = await axiosClient.post(url, body,
    {
      headers: { "Content-Type": "application/json" },

    },
  )

  return response
}

export const changePasswordUserService = async (token, body) => {
  tokenAuth(token, "OSM");
  const url = `user/me/password`
  const response = await axiosClient.post(url, body, {
    headers: { "Content-Type": "application/json" },
  },
  )

  return response
}