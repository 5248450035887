import React, { useState, useEffect, useContext, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { getSgById } from "@Services/shippingGroupService"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("DetailShippingGroup").render()

const ShowShippingGroupScreen = () => {
  const { shippingGroupId } = useParams()
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const [info, setInfo] = useState({})
  const [load, setLoad] = useState(true)
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.SHIPPING_GROUPS, rsc.SG_LIST_SG_SHOW],
        [rsc.SgListSg, rsc.SG_LIST_SG_LIST],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("detail-shipping-groups"),
      routes: [
        { name: t("orders"), route: "/shipping_groups" },
        {
          name: t("detail-shipping-groups"),
          route: `/shipping_groups/${shippingGroupId}`,
        },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getSG()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSG = async () => {
    try {
      let response = await getSgById(user?.token, shippingGroupId)
      if (response?.data?.message) {
        setInfo(response.data.message)
        setLoad(false)
      }
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component info={info} orderid={info?.orderId || ""} load={load} />
    </Suspense>
  )
}

export default ShowShippingGroupScreen
