/* eslint-disable no-template-curly-in-string */
import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormLabel from "@material-ui/core/FormLabel"

import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import CircularProgress from "@material-ui/core/CircularProgress"
import TableBody from "@material-ui/core/TableBody"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ClearIcon from "@material-ui/icons/Clear"
import CheckIcon from "@material-ui/icons/Check"
import useStyles from "@Components/FormStock/styles"
import { useForm, Controller } from "react-hook-form"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import { schemaValidation } from "./configForm"
import { setSequenceConfig } from "@Services/orderService"
import { yupResolver } from "@hookform/resolvers/yup"
import Swal from "sweetalert2"
import { useTranslation } from "react-i18next"

const FormIncrementalOmnixId = (props) => {
  const { load } = props
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const history = useHistory()
  const classes = useStyles()
  const { control, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schemaValidation),
  })
  const { t } = useTranslation("global")

  const onSubmit = async (data) => {
    try {
      await setSequenceConfig(user?.token, data)
      Swal.fire(
        t("Success"),
        t("incrementalOmnixId-configured-correctly"),
        "success"
      )
      history.push("/settings/incremental_omnix_id")
    } catch (error) {
      if (error.response.status === 401) {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      } else {
        Swal.fire(t("Error"), error?.response?.data?.message, "error")
      }
    }
  }

  const optionsPrefix = [
    "${order.site}",
    "${order.shippingType}",
    "${order.deliveryType}",
    "${order.salesChannelId}",
    "${order.channel}",
    "${order.ownerId}",
  ]

  const ButtonsAction = ({ isSubmitting }) => {
    return (
      <div className={classes.inputGroupActions}>
        <Button
          type="reset"
          disabled={Boolean(isSubmitting)}
          variant="contained"
          size="small"
          className={classes.cancelButton}
          endIcon={<ClearIcon />}
          onClick={() => history.goBack()}
        >
          {t("cancel")}
        </Button>
        <Button
          type="submit"
          disabled={Boolean(isSubmitting)}
          variant="contained"
          size="small"
          className={classes.createButton}
          endIcon={<CheckIcon />}
        >
          {isSubmitting ? t("wait") : t("create")}
        </Button>
      </div>
    )
  }

  return (
    <div>
      {load === false ? (
        <form
          className={classes.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ButtonsAction isSubmitting={formState.isSubmitting} />
          <FormLabel component="legend" className={classes.labelGroup}>
            {t("prefix")}
          </FormLabel>
          <div className={classes.inputGroup}>
            <Controller
              render={({ value, onChange }) => (
                <Autocomplete
                  value={value}
                  options={optionsPrefix}
                  onChange={(e, value) => onChange(value)}
                  className={classes.input}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("prefix") + "*"}
                      variant="outlined"
                      error={Boolean(errors.prefix)}
                    />
                  )}
                />
              )}
              control={control}
              defaultValue=""
              name="prefix"
            />
          </div>
          <FormLabel component="legend" className={classes.labelGroup}>
            {t("prefix-separator")}
          </FormLabel>
          <div className={classes.inputGroup}>
            <Controller
              name="prefixSeparator"
              defaultValue=""
              control={control}
              render={({ value, onChange }) => (
                <TextField
                  label={t("prefix-separator") + "*"}
                  variant="outlined"
                  className={classes.input}
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors?.prefixSeparator)}
                />
              )}
            />
          </div>
          <FormLabel component="legend" className={classes.labelGroup}>
            {t("suffix")}
          </FormLabel>
          <div className={classes.inputGroup}>
            <Controller
              name="suffix"
              defaultValue=""
              control={control}
              render={({ value, onChange }) => (
                <TextField
                  label={t("suffix") + "*"}
                  variant="outlined"
                  className={classes.input}
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors?.suffix)}
                />
              )}
            />
          </div>
          <FormLabel component="legend" className={classes.labelGroup}>
            {t("suffix-separator")}
          </FormLabel>
          <div className={classes.inputGroup}>
            <Controller
              name="suffixSeparator"
              defaultValue=""
              control={control}
              render={({ value, onChange }) => (
                <TextField
                  label={t("suffix-separator") + "*"}
                  variant="outlined"
                  className={classes.input}
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors?.suffixSeparator)}
                />
              )}
            />
          </div>
          <FormLabel component="legend" className={classes.labelGroup}>
            {t("size")}
          </FormLabel>
          <div className={classes.inputGroup}>
            <Controller
              name="length"
              defaultValue=""
              control={control}
              render={({ value, onChange }) => (
                <TextField
                  label={t("size") + "*"}
                  variant="outlined"
                  className={classes.input}
                  type="number"
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors?.length)}
                />
              )}
            />
          </div>
          <ButtonsAction isSubmitting={formState.isSubmitting} />
        </form>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <span className={classes.LoaderContainer}>
                    <CircularProgress />
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default FormIncrementalOmnixId
