import { locationCasaideas } from "./LocationsCodes/es_casaideas";

const casaideasTranslate = {
  ...locationCasaideas,
  AWAITING_STORE_CONFIRMATION: "Ingresada",
  PENDING_PACKAGE: "Empacado",
  AWAITING_DELIVERY: "Listo para despachar",
  IN_TRANSIT: "En ruta",
  READY_FOR_PICKUP: "Listo para recoger",
  DELIVERED_INCOMPLETE: "Entregado parcialmente",
  CANCELED: "Cancelado",
  RESERVED: "Creada",
  PENDING_PREPARATION: "Ingresada",
  CLOSED: "Cerrada",
  DELIVERED: "Entregada",
  ShippingGroup: "Grupo de envíos",
  "Criteria0" : "País",
  "Criteria1" : "Región",
  "Criteria2" : "Ciudad",
  "Criteria3" : "Comuna",
  "Criteria4" : "Criterio 4",
  "criteria0" : "País",
  "criteria1" : "Región",
  "criteria2" : "Ciudad",
  "criteria3" : "Comuna",
  "criteria4" : "Criterio 4",
  "RUT": "Id del Cliente",
  "output_of_shippingroup_retry":"Grupo de envíos reintento",
  "commune":"Comuna",
  "region":"Region",
  "orders_test_v2": "Reporte de órdenes V2"
}

export default casaideasTranslate
