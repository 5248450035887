/**
 * Class: Env
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @return {Env}
 */
export default class Env {
  static _comment = "Information about the environment. @version 1.0.1 @author Naldo Duran<naldorck@gmail.com>"

  static clients = [
    "localhost", // 0
    "sandbox", // 1
    "adretail", // 2
    "casaideas", // 3
    "logytech", // 4
    "nave", // 5
    "sodimac", // 6
    "tps", // 7
    "shiperto", // 8
    "entel", // 9
    "wom", // 10
    "privilege", // 11
  ]

  static clients_permissions_v3 = [
    this.clients[1],
    this.clients[3],
    this.clients[4],
    this.clients[7],
    this.clients[8],
    this.clients[9],
  ]

  static children_clients = [
    {
      client: this.clients[4],
      children: this.clients[8],
    },
  ]

  static env = [
    "dev", // 0
    "uat", // 1
    "prod", // 2
  ]

  /*
    MUST KNOW: Shiperto and logytech are the same client website.
    File: src/components/core/Multiclient.jsx  Lines: 22.
    To work in localhost you must replace the variable simulation_client directly
      WARNING: Please, dont push this file if you are working shiperto under this condition
      Example: 
        Old -> 
          return [simulation_client, simulation_env]
        New ->
          return ["shiperto", simulation_env]         
  */
  static defaults = {
    client: this.clients[3],
    env: this.env[1],
  }

  static sg_shipping_types = ["HD", "SP", "RT", "TR", "HDR"]

  static sg_status = [
    "AWAITING_STORE_CONFIRMATION", // 0
    "PENDING_PACKAGE", // 1
    "AWAITING_DELIVERY", // 2
    "IN_TRANSIT", // 3
    "READY_FOR_PICKUP", // 4 // No aplica privilege
    "IN_SOURCE_PREPARATION", // 5  ? sandbox
    "RETURN_IN_REVIEW", // 6 ? sandbox: target
    "DELIVERED", // 7
    "CANCELED", // 8
    "CANCELED_DECREASE", // 9 ? sandbox
    "CLOSED", // 10
    "IN_TRANSIT_FOR_RECEPTION", // 11  ? wom: target
    "READY_FOR_PICKUP_SOURCE", // 12 privilege: target
    "IN_TRANSIT_SOURCE", // 13 privilege: target
    "IN_TRANSIT_FOR_RECEPTION_OPL", // 14  ? wom: target
    "RESCHEDULED", // 15
    "RETURNED", // 16
    "IN_RETURN_COURIER", // 17
  ]

  static sg_actions = [
    "accept", // 0
    "packed", // 1
    "shipped", // 2
    "delivered", // 3
    "prepared", // 4
    "delivered_reject", // 5
    "picked_up", // 6
    "reject", // 7
    "cancel", // 8
    "cancel_decrease", // 9
    "cancelLogistic ", // 10
    "keep", // 11
    "discard", // 12
    "reschedule", // 13
    "courier_reject", // 14
    "delivered_return", // 15
  ]

  static theme = {
    core: {
      id: "core",
      sg: {
        shipping_types: this.sg_shipping_types,
        default: {
          status: this.sg_status,
          actions: this.sg_actions,
        },
      },
    },
  }
}
