import core from "@Config/permissions/root.json"
import tps from "@Config/permissions/tps.json"
import casaideas from "@Config/permissions/casaideas.json"
import { MultiClient, Env } from "@Classes"

const getPermissions = () => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  let clients = {
    core,
    casaideas,
    logytech: casaideas,
    tps,
  }
  let result = clients.hasOwnProperty(_client) ? clients[_client] : clients.core
  return result
}

export const loadButtonToLoadReadPermissions = () => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  let clientsToExclude = ["tps"]
  let result = Boolean(!clientsToExclude.includes(_client))
  return result
}

export default getPermissions
