import React, { useEffect, useState, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory, useParams } from "react-router"
import { getUserByIdService } from "@Services/securityService"
import { LoadComponentByCondition } from "@Methods"
import { useEnv } from "@Hooks"

const Component = new LoadComponentByCondition("DetailUser").render()

const UserShowScreen = () => {
  const user = useEnv().multiclient.get_user()
  const { handleRoutingBreadcrumbs, handleErrorSession } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const [currentUser, setCurrentUser] = useState({})
  const [load, setLoad] = useState(true)
  const history = useHistory()
  const { userId } = useParams()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.SETTINGS, rsc.SETTING_USER_SHOW_SECURITY_SCHEMAS]
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("detail-user"),
      routes: [
        { name: t("settings"), route: "/settings" },
        { name: t("security-schemas"), route: "/settings/security_schemas" },
        { name: t("users"), route: "/settings/security_schemas/users" },
        { name: t("detail-user"), route: `/settings/security_schemas/users/${userId}` },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getUserAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUserAction = async () => {
    try {
      setLoad(true)
      let response = await getUserByIdService(user?.token, userId)
      setCurrentUser(response.data.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component load={load} currentUser={currentUser} getUserAction={getUserAction} />
    </Suspense>
  )
}

export default UserShowScreen
