import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  inputGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  input: {
    flexGrow: 1,
    margin: "10px 5px",
  },
  contLabelSwitch: {
    margin: "20px",
  },
  labelSwitch: {
    fontWeight: 500,
    marginBottom: 5,
    fontSize: 14,
  },
  labelGroup: {
    fontSize: 14,
    fontWeight: 500,
    margin: "15px 20px",
  },
  switchElement: {
    margin: -5
  },
  contMainPermissions: {
    width: "100%",
    margin: "10px 5px",
    display: "flex",
    flexDirection: "column"
  },
  contMainLabelAllPermissions: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  new_user_screen__container_list_permissions: {
    width: "100%",
    transition: "all 0.3s ease-in",
    maxHeight: "26rem",
    overflowY: "scroll",
    border: "1px solid #716F87",
    padding: "0.5em",
    marginTop: "0.5em",
    borderRadius: "0.5rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.1rem",
  },
  cont_label_switch_perm_main: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label_switch__label: {
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily,
    "&:hover, &:focus": {
      color: "#FD0000",
    },
  },
  new_user_screen__content_checked_2: {
    paddingLeft: "1.5rem"
  },
  new_user_screen__content_checked_3: {
    paddingLeft: "2.5rem"
  }
}))
