/**
 * Constant AuthProvider return an Element and save user information to local storage
 * @param props IContext.
 * @return {Element} AuthContext.Provider
 */
import { useEffect, useReducer } from "react"
import { AuthReducerInit } from "@Context/v3/inits"
import { AuthReducer } from "@Context/v3/reducers"
import { AuthContext } from "@Context/v3/context"
import { WEB_APP_USER } from "@Helpers/constHelper"

const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(AuthReducer, AuthReducerInit())

  useEffect(() => {
    if (!state.user) {
      localStorage.removeItem(WEB_APP_USER)
      return
    }
    localStorage.setItem(WEB_APP_USER, JSON.stringify(state))
  }, [state])

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
