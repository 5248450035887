import { MultiClient, Env } from "@Classes"
import { core, casaideas, sandbox, sodimac, tps, entel } from "./clients"

const displayStatusByTypeHelper = ({
  type, // Can be "order" or "sg"
  status,
  shippingType,
  reason,
  sgs,
}) => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const clients = {
    core,
    casaideas: casaideas({ reason }),
    sandbox,
    sodimac: sodimac({ type, status, reason, sgs }),
    tps,
    entel
  }
  let statusClient = clients?.[_client]?.[status]?.[shippingType]?.[type]
  let statusCore = clients?.core?.[status]?.[shippingType]?.[type]
  return statusClient || statusCore || status
}

export default displayStatusByTypeHelper
