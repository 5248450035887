import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useEnv } from "@Hooks"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormUsers").render()

const UserNewScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.SETTINGS, rsc.SETTING_USER_NEW_SECURITY_SCHEMAS]
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("create-user"),
      routes: [
        { name: t("settings"), route: "/settings" },
        { name: t("security-schemas"), route: "/settings/security_schemas" },
        { name: t("users"), route: "/settings/security_schemas/users" },
        { name: t("create-user"), route: "/settings/security_schemas/users/new" },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component load={false} />
    </Suspense>
  )
}

export default UserNewScreen
