import React, { useContext } from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import getRoutes from "@Website/getRoutes"
import { UseSessionEffect } from "@Hooks/useSessionEffect"
import { TableContext } from "@Context/context/TableContext"
import { resetTable } from "@Context/actions/TableActions"
import { GlobalContext } from "@Context/context/GlobalState"

const DashboardRouter = () => {
  const routeList = getRoutes()
  const { session } = UseSessionEffect()
  const { setTable } = useContext(TableContext)
  const { handleErrorSession } = useContext(GlobalContext)

  React.useEffect(() => {
    if (!session) {
      setTable(resetTable())
      handleErrorSession({ error: true, errorCode: 401 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  return (
    <BrowserRouter>
      <Switch>
        {routeList.map((route, index) => {
          return <Route key={index} path={route.path} exact component={route.component} />
        })}
        <Redirect to="/dashboard" />
      </Switch>
    </BrowserRouter>
  )
}

export default DashboardRouter
