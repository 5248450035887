import { Fragment } from "react"
import Switch from "@material-ui/core/Switch"
import useStyles from "@Components/core/Permissions/styles"
import _translate from "@Translates/index"

const LabelSwitch = ({ id, label, onChange, value, checked }) => {
  const classes = useStyles()

  return (
    <Fragment>
      <div className={classes.cont_label_switch_perm_main}>
        <label htmlFor={id} className={classes.label_switch__label}>
          {_translate(label, true)}
        </label>
        <Switch className={classes.switchElement} id={id} checked={checked} onChange={onChange} value={value} />
      </div>
    </Fragment>
  )
}

export default LabelSwitch
