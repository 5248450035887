import {useState, useEffect, useContext} from 'react'
import { getMedia } from '@Services/mediaService'
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"

const useGetSgFilesOOM = (statuses) => {
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const [sgFiles, setSgFiles] = useState([])
  const [fileIds, setFileIds] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    const ids = (statuses || []).flatMap(({media}) => {
      if (media) {
        return (media?.ref || []).map(({id}) => id)
      }
      return null
    })
    setFileIds(ids.filter(Boolean))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses])

  useEffect(() => {
    !!fileIds.length && getFilesFromSgsStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileIds])

  const getFilesFromSgsStatuses = async () => {
    try {
      setLoad(true)
      const response = await getMedia(user?.token, {
        ids: fileIds,
      })
      const result = response?.data?.message || []
      setSgFiles(result)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      if (error?.response?.status !== 404) {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      }
    }
  }

  return {
    sgFiles,
    loadSgFiles: load
  }
}

export default useGetSgFilesOOM