import React from "react"
import AppRouter from "@Routes/AppRouter"
import { AuthProvider } from "@Context/v3/providers"
import TableProvider from "@Context/context/TableContext"
import "@Webcomponents/OmnixCustomData/index"
import "@Translations"

const AppContainer = () => {
  return (
    <AuthProvider>
      <TableProvider>
        <AppRouter />
      </TableProvider>
    </AuthProvider>
  )
}

export default AppContainer
