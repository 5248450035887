import { Permissions as PermissionsClass } from "@Classes"
import Permission from "@Components/core/Permissions/Permission"

const Permissions = (props) => {
  let template = []
  const { system_permission_list, set_user_permission_list, user_permission_list } = props
  const permissions = new PermissionsClass()

  for (const x of system_permission_list) {
    template.push(
      <Permission
        key={`${x.parent ?? ""}-${x.id}`}
        {...x}
        set_user_permission_list={set_user_permission_list}
        system_permission_list={system_permission_list}
        checked={permissions.is_enable(x, user_permission_list)}
      />,
    )
  }

  return template
}

export default Permissions
