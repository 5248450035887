import HomeScreen from "@Pages/home"
import {
  ListOrderScreen,
  NewOrderScreen,
  // NewComplexOrderScreen,
  EditOrderScreen,
  ReturnOrderScreen,
  TransferOrderScreen,
  PosOrderScreen,
  ShowOrderScreen,
  SelectNew,
} from "@Pages/OrderScreens"
import {
  ListShippingGroup,
  ListShippingGroupAccept,
  RescheduleShippingGroupScreen,
  ShowShippingGroupScreen,
} from "@Pages/ShippingGroupScreens"
import { Item, ListItems, CreateItem, EditItem, SafetyScreen } from "@Pages/ItemScreens"
import { SourceCapacityList, NewSourceScreen, EditSourceScreen, ShowSourceScreen } from "@Pages/SourceScreens"
import { ListUseCapacityScreen, UpdateMaxCapacityScreen } from "@Pages/UseCapacityScreens"
import { ListRoutesScreen, NewRouteScreen, EditRouteScreen } from "@Pages/RoutesScreens"
import { ListCoverageScreen, NewCoverageScreen, EditCoverageScreen } from "@Pages/CoverageScreens"
import { ShowCalendarScreen, EditCalendarScreen } from "@Pages/CalendarScreens"
import { CourierListScreen, EditCourierScreen, NewCourierScreen } from "@Pages/CouriersScreens"
import { EditLocationScreen, ListLocationScreen, NewLocationScreen } from "@Pages/LocationsScreens"
import { ListScheduleScreen, NewScheduleScreen, EditScheduleScreen } from "@Pages/ScheduleScreens"
import { ListStockScreen, NewStockScreen, EditStockScreen, ShowStockScreen } from "@Pages/StockScreens"
import { ListChannelsScreen, EditChannelScreen, NewChannelScreen } from "@Pages/ChannelsScreens"
import DownloadReportScreen from "@Pages/DowloadReports"
import { ListMassiveLoadsScreen, NewMassiveLoadsScreen } from "@Pages/MassiveLoadsScreen"
import { ProfileScreen } from "@Pages/UsersScreens"
import { GeneralSettingsScreen } from "@Pages/SettingsScreens"
import { ShowIncrementalOmnixIdScreen, NewIncrementalOmnixIdScreen } from "@Pages/IncrementalOmnixIdScreens"
import { HomeSecurityScreen, RoleListScreen, UserShowScreen, UserListScreen, UserNewScreen, UserEditScreen } from "@Pages/SecurityScreens"

const core = [
  // Main
  {
    name: "dashboard",
    path: "/dashboard",
    component: HomeScreen,
  },
  // Orders
  {
    name: "orderList",
    path: "/orders",
    component: ListOrderScreen,
  },
  {
    name: "orderNewSimple",
    path: "/orders/simple/new",
    component: NewOrderScreen,
  },
  // {
  //   name: "orderNewComplex",
  //   path: "/orders/complex/new",
  //   component: NewComplexOrderScreen,
  // },
  {
    name: "orderTransfer",
    path: "/orders/transfer/new",
    component: TransferOrderScreen,
  },
  {
    name: "orderPos",
    path: "/orders/pos/new",
    component: PosOrderScreen,
  },
  {
    name: "orderReturn",
    path: "/orders/return/new",
    component: ReturnOrderScreen,
  },
  {
    name: "orderEdit",
    path: "/orders/edit/:orderId",
    component: EditOrderScreen,
  },
  {
    name: "orderSelectNew",
    path: "/orders/select-new",
    component: SelectNew,
  },
  {
    name: "orderShow",
    path: "/orders/:id",
    component: ShowOrderScreen,
  },
  // Shipping groups
  {
    name: "shippingGroupList",
    path: "/shipping_groups",
    component: ListShippingGroup,
  },
  {
    name: "shippingGroupAccept",
    path: "/shipping_groups_accept",
    component: ListShippingGroupAccept,
  },
  {
    name: "shippingGroupReschedule",
    path: "/shipping_groups/:id/reschedule",
    component: RescheduleShippingGroupScreen,
  },
  {
    name: "shippingGroupShow",
    path: "/shipping_groups/:shippingGroupId",
    component: ShowShippingGroupScreen,
  },
  // Items
  {
    name: "itemList",
    path: "/items",
    component: ListItems,
  },
  {
    name: "itemNew",
    path: "/items/new",
    component: CreateItem,
  },
  {
    name: "itemEdit",
    path: "/items/edit/:channel/:id",
    component: EditItem,
  },
  {
    name: "itemShow",
    path: "/items/:channel/:id",
    component: Item,
  },
  {
    name: "itemSafety",
    path: "/items/safety",
    component: SafetyScreen,
  },
  // Sources
  {
    name: "sourceList",
    path: "/sources",
    component: SourceCapacityList,
  },
  {
    name: "sourceNew",
    path: "/sources/new",
    component: NewSourceScreen,
  },
  {
    name: "sourceEdit",
    path: "/sources/edit/:sourceId",
    component: EditSourceScreen,
  },
  {
    name: "sourceShow",
    path: "/sources/:sourceId",
    component: ShowSourceScreen,
  },
  // Use Capacity
  {
    name: "useCapacityList",
    path: "/use-capacity",
    component: ListUseCapacityScreen,
  },
  {
    name: "useCapacityEdit",
    path: "/edit-capacity/",
    component: UpdateMaxCapacityScreen,
  },
  // Routes
  {
    name: "routeList",
    path: "/routes",
    component: ListRoutesScreen,
  },
  {
    name: "routeNew",
    path: "/routes/new",
    component: NewRouteScreen,
  },
  {
    name: "routeEdit",
    path: "/routes/edit/:routeId",
    component: EditRouteScreen,
  },
  // Coverage
  {
    name: "coverageList",
    path: "/coverage",
    component: ListCoverageScreen,
  },
  {
    name: "coverageNew",
    path: "/coverage/new",
    component: NewCoverageScreen,
  },
  {
    name: "coverageEdit",
    path: "/coverage/edit/:coverageId",
    component: EditCoverageScreen,
  },
  // Calendar
  {
    name: "calendarShow",
    path: "/calendar",
    component: ShowCalendarScreen,
  },
  {
    name: "calendarEdit",
    path: "/calendar/:id",
    component: EditCalendarScreen,
  },
  // Couriers
  {
    name: "courierList",
    path: "/couriers",
    component: CourierListScreen,
  },
  {
    name: "courierNew",
    path: "/couriers/new",
    component: NewCourierScreen,
  },
  {
    name: "courierEdit",
    path: "/couriers/edit/:courierId",
    component: EditCourierScreen,
  },
  // Locations
  {
    name: "locationList",
    path: "/locations",
    component: ListLocationScreen,
  },
  {
    name: "locationNew",
    path: "/locations/new",
    component: NewLocationScreen,
  },
  {
    name: "locationEdit",
    path: "/locations/edit",
    component: EditLocationScreen,
  },
  // Schedule
  {
    name: "scheduleList",
    path: "/schedule",
    component: ListScheduleScreen,
  },
  {
    name: "scheduleNew",
    path: "/schedule/new",
    component: NewScheduleScreen,
  },
  {
    name: "scheduleEdit",
    path: "/schedule/edit/:scheduleId",
    component: EditScheduleScreen,
  },
  // Stock
  {
    name: "stockList",
    path: "/stock",
    component: ListStockScreen,
  },
  {
    name: "stockNew",
    path: "/stock/new",
    component: NewStockScreen,
  },
  {
    name: "stockEdit",
    path: "/stock/edit/:stockId",
    component: EditStockScreen,
  },
  {
    name: "stockShow",
    path: "/stock/:stockId",
    component: ShowStockScreen,
  },
  // Channels
  {
    name: "channelList",
    path: "/channels",
    component: ListChannelsScreen,
  },
  {
    name: "channelNew",
    path: "/channels/new",
    component: NewChannelScreen,
  },
  {
    name: "channelEdit",
    path: "/channels/edit/:channelId",
    component: EditChannelScreen,
  },
  // Download Reports
  {
    name: "downloadReport",
    path: "/dowloadReports",
    component: DownloadReportScreen,
  },
  // Massive Loads
  {
    name: "massiveLoads",
    path: "/massive_loads",
    component: ListMassiveLoadsScreen,
  },
  {
    name: "massiveLoadsNew",
    path: "/massive_loads/new",
    component: NewMassiveLoadsScreen,
  },
  // Users
  {
    name: "userProfile",
    path: "/profile",
    component: ProfileScreen,
  },
  // Settings
  {
    name: "settings",
    path: "/settings",
    component: GeneralSettingsScreen,
  },
  // Security
  {
    name: "HomeSecurityScreen",
    path: "/settings/security_schemas",
    component: HomeSecurityScreen,
  },
  {
    name: "UserListScreen",
    path: "/settings/security_schemas/users",
    component: UserListScreen,
  },
  {
    name: "UserNewScreen",
    path: "/settings/security_schemas/users/new",
    component: UserNewScreen,
  },
  {
    name: "UserEditScreen",
    path: "/settings/security_schemas/users/edit/:userId",
    component: UserEditScreen,
  },
  {
    name: "UserShowScreen",
    path: "/settings/security_schemas/users/:userId",
    component: UserShowScreen,
  },
  {
    name: "RoleListScreen",
    path: "/settings/security_schemas/roles",
    component: RoleListScreen,
  },
  // Incremental Omnix Id
  {
    name: "incrementalOmnixId",
    path: "/settings/incremental_omnix_id",
    component: ShowIncrementalOmnixIdScreen,
  },
  {
    name: "incrementalOmnixIdNew",
    path: "/settings/incremental_omnix_id/new",
    component: NewIncrementalOmnixIdScreen,
  },
]

export default core
