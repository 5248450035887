import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { LoadComponentByCondition } from "@Methods"
import { useEnv } from "@Hooks"
const Component = new LoadComponentByCondition("TableCalendar").render()

const CalendarScreen = () => {
  const { t } = useTranslation("global")
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)  
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.CALENDAR, rsc.CALENDAR_LIST_CALENDAR_LIST],
        [rsc.CaListCalendar, rsc.CALENDAR_LIST_CALENDAR_LIST],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("calendar"),
      routes: [
        { name: t("logistics"), route: "/calendar" },
        { name: t("calendar"), route: "/calendar" },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component />
    </Suspense>
  )
}

export default CalendarScreen
