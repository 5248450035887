import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Permissions as PermissionsClass } from "@Classes"
import LabelSwitch from "@Components/core/Permissions/LabelSwitch"
import Permissions from "@Components/core/Permissions/Permissions"
import FormLabel from "@material-ui/core/FormLabel"
import useStyles from "@Components/core/Permissions/styles"

const PermissionList = (props) => {
  const classes = useStyles()
  const { t } = useTranslation("global")
  const {
    userData,
    user_permission_list,
    set_user_permission_list,
    set_user_permission_list_all,
  } = props
  const [system_permission_list, set_system_permission_list] = useState([])
  const permissions = new PermissionsClass()

  useEffect(() => {
    if (!!userData) {
      set_user_permission_list(permissions.format(userData?.rolePermissions ?? {}, true))
    }
    //eslint-disable-next-line
  }, [userData])

  useEffect(() => {
    set_system_permission_list(permissions.format(permissions.get_list("root")))
    //eslint-disable-next-line
  }, [user_permission_list])

  const template = (
    <div>
      <FormLabel component="legend" className={classes.labelGroup} style={{ textTransform: "uppercase" }}>
        {t("permissions")}
      </FormLabel>

      <div className={classes.contMainPermissions}>
        <div className={classes.contMainLabelAllPermissions}>
          <p style={{ marginTop: "1rem" }}></p>
          <LabelSwitch
            id="perm--all-permissions"
            value="all-permissions"
            label="Asignar todos los permisos"
            onChange={(e) =>
              permissions.change_list_all(
                e,
                system_permission_list,
                set_user_permission_list,
                set_user_permission_list_all,
              )
            }
          />
        </div>
        <div className={classes.new_user_screen__container_list_permissions}>
          <Permissions
            system_permission_list={system_permission_list}
            set_user_permission_list={set_user_permission_list}
            user_permission_list={user_permission_list}
          />
        </div>
      </div>
    </div>
  )

  return template
}

export default PermissionList
