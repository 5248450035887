/**
 * that he reduces with the different cases of the actions to log in and log out of the user. and also set the menu that the user is allowed to enter
 * @param state
 * @param action
 * @return {Objet}
 * @return {IAuth} state. returns the state when authenticated
 * @return {Boolean} loading. returns if it is loading or not according to the selected case
 * @return {Strung} error. returns message of error
 * @return {Array} user. returns Info of user autenticated
 * @return {Array} optionsMenuAccess. returns Info of menu for user
 */
export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "[auth] Login start":
      return {
        user: null,
      }

    case "[auth] Login error":
      return {
        user: null,
      }

    case "[auth] Login success": {
      const { payload } = action

      return {
        ...state,
        ...payload,
      }
    }

    case "[auth] Logout":
      return {
        user: null,
      }

    case "[auth] Change user info":
      const { payload } = action

      return {
        ...state,
        user: { ...state.user, ...payload },
      }

    default:
      return state
  }
}
