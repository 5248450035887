import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormLabel from "@material-ui/core/FormLabel"
import Input from "@material-ui/core/Input"

import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import CircularProgress from "@material-ui/core/CircularProgress"
import TableBody from "@material-ui/core/TableBody"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ClearIcon from "@material-ui/icons/Clear"
import CheckIcon from "@material-ui/icons/Check"
import useStyles from "@Components/FormStock/styles"
import { useForm, Controller } from "react-hook-form"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import { schemaValidation } from "./configForm"
import { uploadMassiveLoadFile } from "@Services/massiveLoadsService"
import { yupResolver } from "@hookform/resolvers/yup"
import Swal from "sweetalert2"
import { useTranslation } from "react-i18next"
const FormStockAdretailDEV = (props) => {
  const { load } = props
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const history = useHistory()
  const classes = useStyles()
  const { control, register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schemaValidation),
  })
  const { t } = useTranslation("global")
  const onSubmit = async (data) => {
    try {
      const { type, file } = data
      const formData = new FormData()
      formData.append("file", file[0])
      let response = await uploadMassiveLoadFile(
        user?.token,
        formData,
        type
      )
      Swal.fire(
        t("Success"),
        `${t("load-done-correctly-to-check-the-status-is-this-id")} ${
          response?.data?.message?.id || ""
        }`,
        "success"
      )
      history.push("/massive_loads")
    } catch (error) {
      if (error.response.status === 401) {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      } else {
        Swal.fire(t("Error"), error?.response?.data?.message, "error")
      }
    }
  }

  const convertType = (type) => {
    let types = {
      routes: t("Route"),
      coverages: t("Coverage"),
      couriers: t("Courier"),
    }
    return types.hasOwnProperty(type) ? types[type] : ""
  }

  const ButtonsAction = ({ isSubmitting }) => {
    return (
      <div className={classes.inputGroupActions}>
        <Button
          type="reset"
          disabled={Boolean(isSubmitting)}
          variant="contained"
          size="small"
          className={classes.cancelButton}
          endIcon={<ClearIcon />}
          onClick={() => history.goBack()}
        >
          {t("cancel")}
        </Button>
        <Button
          type="submit"
          disabled={Boolean(isSubmitting)}
          variant="contained"
          size="small"
          className={classes.createButton}
          endIcon={<CheckIcon />}
        >
          {isSubmitting ? t("wait") : t("create")}
        </Button>
      </div>
    )
  }

  return (
    <div>
      {load === false ? (
        <form
          className={classes.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ButtonsAction isSubmitting={formState.isSubmitting} />
          <FormLabel component="legend" className={classes.labelGroup}>
            {t("type-of-load")}
          </FormLabel>
          <div className={classes.inputGroup}>
            <Controller
              render={({ value, onChange }) => (
                <Autocomplete
                  name="type"
                  value={value}
                  options={["routes", "coverages", "couriers"]}
                  onChange={(e, value) => onChange(value)}
                  className={classes.input}
                  getOptionLabel={(option) => convertType(option)}
                  getOptionSelected={(option, value) =>
                    convertType(option) === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("type") + "*"}
                      variant="outlined"
                      error={Boolean(errors.type)}
                    />
                  )}
                />
              )}
              control={control}
              defaultValue=""
              name="type"
            />
          </div>
          <FormLabel component="legend" className={classes.labelGroup}>
            {t("load-file")}
          </FormLabel>
          <div className={classes.inputGroup}>
            <Input
              inputRef={register}
              name="file"
              type="file"
              className={classes.input}
              error={Boolean(errors.file)}
            />
          </div>
          <ButtonsAction isSubmitting={formState.isSubmitting} />
        </form>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <span className={classes.LoaderContainer}>
                    <CircularProgress />
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default FormStockAdretailDEV
