import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useEnv } from "@Hooks"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormChannel").render()

const NewChannelScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.CHANNEL, rsc.CHANNEL_BUTTON_CREATE_CHANNEL],
        [rsc.ChCrudCreate, rsc.CHANNEL_BUTTON_CREATE_CHANNEL],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("create-sales-channel"),
      routes: [
        { name: t("inventory"), route: "/channels" },
        { name: t("create-sales-channel"), route: "/channels/new" },
      ],
      icon: <AllInboxIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component edit={false} load={false} />
    </Suspense>
  )
}

export default NewChannelScreen
