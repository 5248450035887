import core from "./core"
import tps from "./tps"

import { MultiClient, Env } from "@Classes"

const getRoutes = () => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  let clients = {
    core,
    tps
  }
  let result = clients.hasOwnProperty(_client) ? clients[_client] : clients.core
  return result
}

export default getRoutes;