import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import { useTranslation } from "react-i18next"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useEnv } from "@Hooks"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("TableItems").render()

const ListItems = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const history = useHistory()

  const { multiclient } = useEnv()

  useEffect(() => {
    if (
      !multiclient.permissions_v3_on(
        [rsc.ITEMS, rsc.ITEM_LIST_ITEM_LIST],
        [rsc.ItListItems, rsc.ITEM_LIST_ITEM_LIST],
      )
    ) {
      return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("articles"),
      routes: [
        { name: t("inventory"), route: "/items" },
        { name: t("articles"), route: "/items" },
      ],
      icon: <AllInboxIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component />
    </Suspense>
  )
}

export default ListItems
