import React, { useState, useContext } from "react"
import { useHistory } from "react-router"
import { useForm, Controller } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  loginError,
  loginStart,
  loginSuccess,
} from "@Context/actions/AuthActions"
import { authenticateUser } from "@Services/authService"
import { AuthContext } from "@Context/v3/context"
import Swal from "sweetalert2"

// import loginLogo from "@Img/login/LogInLogo.png"
import OmnixLogo from "@Img/omnix-logo.svg"
import OmnixLogoNew from "@Img/logo-omnix-new.png"
// import loginDestacada from "@Img/login/LoginDestacada.png"
import loginDestacadaNew from "@Img/login/fondo-person-new.png"
// Styles
import CssBaseline from "@material-ui/core/CssBaseline"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useStyles from "@Components/Authentication/styles"
import { useTheme } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Button from "@material-ui/core/Button"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import SelectLanguage from "@Components/SelectLanguage"
import { useTranslation } from "react-i18next"

const Auth = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const { t } = useTranslation("global")
  const { dispatch } = useContext(AuthContext)
  const widthMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [showPassword, setshowPassword] = useState(false)
  const [load, setLoad] = useState(false)
  const { control, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(
      Yup.object({
        user: Yup.string()
          .max(255, "Maximum-255-characters")
          .required("required-field"),
        password: Yup.string()
          .max(255, "Maximum-255-characters")
          .required("required-field"),
      })
    ),
  })

  const onSubmit = async (data) => {
    try {
      loginStart()
      setLoad(true)
      let response = await authenticateUser(data)
      dispatch(loginSuccess({ user: { ...response.data.message } }))
      const startDate = new Date()
      const newDate = startDate.setHours(startDate.getHours() + 3)
      window.localStorage.setItem("finalDate", newDate.toString())
      history.replace("/dashboard")
    } catch (error) {
      loginError()
      setLoad(false)
      switch (error.response.status) {
        case 401:
          return Swal.fire({
            title: t("Wrong-credentials!"),
            icon: "error",
            showConfirmButton: false,
            showCloseButton: true,
          })
        default:
          return Swal.fire({
            title: t("Internal-error!"),
            icon: "error",
            showConfirmButton: false,
            showCloseButton: true,
          })
      }
    }
  }

  return (
    <>
      <CssBaseline />
      <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
        {!widthMobile && (
          <div className={classes.contLeft}>
            <img
              src={OmnixLogoNew}
              alt="Omnix"
              className={classes.loginDesktopLogo}
            />
            <img
              src={loginDestacadaNew}
              alt="Interactive img"
              className={classes.interactiveImg}
            />
            {/* <Typography
              component="h5"
              variant="h5"
              className={classes.descriptionText}
            >
              {t("initial-slogan")}
            </Typography> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                component="h6"
                variant="h6"
                className={classes.subDescriptionText}
              >
                Omnix © {new Date().getFullYear()}
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                className={classes.subDescriptionText}
              >
                {t("copyright")}
              </Typography>
            </div>
          </div>
        )}
        <div className={widthMobile ? classes.contMobile : classes.contRight}>
          <div className={classes.constTopRight}>
            {/* Dropdown for multi language here! */}
            {/* <SelectLanguage /> */}
            <SelectLanguage />
          </div>
          <div className={classes.constCenterRight}>
            {widthMobile && (
              <img
                src={OmnixLogo}
                alt={t("initial-slogan")}
                className={classes.loginImgMobile}
              />
            )}
            <div className={classes.bodyFormRight}>
              {!widthMobile && (
                <>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.descriptionText}
                >
                  {t("initial-slogan")}
                </Typography>
                <Typography
                  component="h3"
                  variant="h3"
                  className={classes.title}
                >
                  {t("log-in")}
                </Typography>
                </>
              )}
              <Controller
                name="user"
                defaultValue=""
                control={control}
                render={({ value, onChange }) => (
                  <TextField
                    InputLabelProps={{
                      className: classes.formInputLabel,
                    }}
                    autoFocus
                    label={t("user")}
                    variant="outlined"
                    className={classes.input}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.user)}
                    helperText={Boolean(errors.user) && t(errors.user.message)}
                  />
                )}
              />
              <Controller
                name="password"
                defaultValue=""
                control={control}
                render={({ value, onChange }) => (
                  <TextField
                    InputLabelProps={{
                      className: classes.formInputLabel,
                    }}
                    value={value}
                    onChange={onChange}
                    label={t("password")}
                    variant="outlined"
                    className={classes.input}
                    type={showPassword ? "text" : "password"}
                    error={Boolean(errors.password)}
                    helperText={
                      Boolean(errors.password) && t(errors.password.message)
                    }
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setshowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
              <Button
                className={classes.submit}
                size="large"
                variant="contained"
                type="submit"
                disabled={Boolean(formState.isSubmitting)}
              >
                {formState.isSubmitting ? t("wait") : t("submit")}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default Auth
