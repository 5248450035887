import getPermissions from "@Website/getPermissions"

class Permissions {
  #list_root

  constructor() {
    this.#list_root = getPermissions().list
  }

  get_list(type) {
    if (type === "root") {
      return this.#list_root
    }
  }

  format(list, user) {
    const o = []
    const user_permission_state = !!user ? true : false

    for (const x in list) {
      o.push({ id: x, level: 1, parent: null, checked: user_permission_state, value: x })

      if (Array.isArray(list[x])) {
        for (const xx of list[x]) {
          o.push({ id: xx, level: 2, parent: x, checked: user_permission_state, value: x })
        }
      }
    }

    return o
  }

  is_enable(x, user_permission_list) {
    const found = user_permission_list.find((permission) => permission.id === x.id && permission.parent === x.parent)
    return !!found
  }

  change_list(e, set_user_permission_list, system_permission_list) {
    let value = JSON.parse(e.target.value)
    const mode = !e.target.checked ? "del" : "add"
    value.checked = !value.checked

    if (mode === "add") {
      if (!!value.parent) {
        set_user_permission_list((current) => [...current, value])
      } else {
        let values = system_permission_list.filter(
          (permission) => permission.parent === value.id || permission.id === value.id,
        )

        set_user_permission_list((current) => {
          let o = [...current, ...values]
          let y = []

          o.forEach((element) => {
            const found = y.find((yy) => yy.id === element.id && yy.parent === element.parent)
            if (!found) {
              y.push(element)
            }
          })

          return y
        })
      }
    }

    if (mode === "del") {
      if (!!value.parent) {
        set_user_permission_list((current) =>
          current.filter((permission) => value.id !== permission.id || value.parent !== permission.parent),
        )
      } else {
        set_user_permission_list((current) =>
          current.filter((permission) => permission.parent !== value.id && value.id !== permission.id),
        )
      }
    }
  }

  change_list_all(e, system_permission_list, set_user_permission_list, set_user_permission_list_all) {
    const v = e.target.checked
    set_user_permission_list_all(v)

    if (v) {
      set_user_permission_list(system_permission_list)
    } else {
      set_user_permission_list([])
    }
  }
}

export default Permissions
