import {useState, useContext} from 'react'
import { getOrderDetails } from '@Services/orderService'
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import Swal from 'sweetalert2'

const useCheckOrderDetailsOOM = () => {
  const user = useEnv().multiclient.get_user()
  const { handleErrorSession } = useContext(GlobalContext)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [load, setLoad] = useState(false)

  const getOrderInfo = async (orderId) => {
    try {
      setLoad(true)
      const response = await getOrderDetails(user?.token, orderId)
      const order = response?.data?.message || {}
      setCurrentOrder(order)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      if (error?.response?.status !== 404) {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      } else {
        Swal.fire({
          title: 'Error',
          text: 'The Order does not exist',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    }
  }

  return {
    currentOrder,
    loadOrderInfo: load,
    getOrderInfo,
  }
}

export default useCheckOrderDetailsOOM