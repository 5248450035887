import core from "./core"
import casaideas from "./casaideas"
import logytech from "./logytech"
import sodimac from "./sodimac"
import tps from "./tps"

import { MultiClient, Env } from "@Classes"

const getSidebarRouteItems = () => {
  let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  let clients = {
    core,
    casaideas,
    logytech,
    sodimac,
    tps
  }
  let result = clients.hasOwnProperty(_client) ? clients[_client] : clients.core
  return result
}

export default getSidebarRouteItems;