import HomeScreen from "@Pages/home"
import {
  ListOrderScreen,
  EditOrderScreen,
  ShowOrderScreen,
} from "@Pages/OrderScreens"
import { ProfileScreen } from "@Pages/UsersScreens"
import { GeneralSettingsScreen } from "@Pages/SettingsScreens"
import {
  SetRedButtonScreen
} from "@Overrides/theme/tps/dev/pages/SettingsScreens"
import { HomeSecurityScreen, UserShowScreen, UserListScreen, UserNewScreen, UserEditScreen } from "@Pages/SecurityScreens"

const tps = [
  // Main
  {
    name: "dashboard",
    path: "/dashboard",
    component: HomeScreen,
  },
  // Orders
  {
    name: "orderList",
    path: "/orders/list/:statusListed",
    component: ListOrderScreen,
  },
  {
    name: "orderEdit",
    path: "/orders/edit/:id",
    component: EditOrderScreen,
  },
  {
    name: "orderShow",
    path: "/orders/:id",
    component: ShowOrderScreen
  },
  // Users
  {
    name: "userProfile",
    path: "/profile",
    component: ProfileScreen,
  },
  // Settings
  {
    name: "settings",
    path: "/settings",
    component: GeneralSettingsScreen,
  },
  {
    name: "setRedButtonStatus",
    path: "/settings/red_button/set_status",
    component: SetRedButtonScreen
  },
  // Security
  {
    name: "HomeSecurityScreen",
    path: "/settings/security_schemas",
    component: HomeSecurityScreen,
  },
  {
    name: "UserListScreen",
    path: "/settings/security_schemas/users",
    component: UserListScreen,
  },
  {
    name: "UserNewScreen",
    path: "/settings/security_schemas/users/new",
    component: UserNewScreen,
  },
  {
    name: "UserEditScreen",
    path: "/settings/security_schemas/users/edit/:userId",
    component: UserEditScreen,
  },
  {
    name: "UserShowScreen",
    path: "/settings/security_schemas/users/:userId",
    component: UserShowScreen,
  },
]

export default tps
